const Full = () => import( '@/container/Full.vue')
// dashboard components
const InventoryDashboardRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/Dashboard.vue')
const InventoryApprovalRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/InventoryApproval.vue')
const StockOverviewRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/StockOverview.vue')
const StockDetailsRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/StockDetails.vue')
const InventoryItemTrackRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/InventoryItemTrack.vue')
const InventoryItemsRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/InventoryItems.vue')
const VendorManagementRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/VendorManagement.vue')
const ManageLocationsRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/ManageLocations.vue')
const InventoryConfigurationRoutes = () => import(/* webpackPrefetch: false */ '@/views/inventory/InventoryConfiguration.vue')
const NewInventoryDashboard = () => import(/* webpackPrefetch: false */ '@/views/inventory/NewDashboard')

export default {
    path: '/inventory',
    component: Full,
    redirect: '/inventory',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/inventory/inventory_dashboard',
            component: InventoryDashboardRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/inventory_item_track/:accountId/:trackSerial',
            component: InventoryItemTrackRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/goods_in_use',
            component: StockDetailsRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/inventory_items',
            component: InventoryItemsRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/vendor_management',
            component: VendorManagementRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/manage_locations',
            component: ManageLocationsRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/inventory_approval',
            component: InventoryApprovalRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/inventory_configuration',
            component: InventoryConfigurationRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/stock',
            component: StockOverviewRoutes,
            meta: {
                title: "Inventory"
            }
        },
        {
            path: '/inventory/newDashboard',
            component: NewInventoryDashboard,
            meta: {
                title: "Inventory"
            }
        },
    ]
}
